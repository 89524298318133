<template>
    <div class="disclaimer xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-text">
                <h3>隐私政策</h3>
                <p>YIN SI ZHENG CE</p>
            </div>
        </div>
        <div class="disclamer-box">
            <div class="disclamer-tit">
                <h3>隐私政策</h3>
            </div>
            <div class="disclamer-content">
                <p>本声明适用于河南千越医疗科技有限公司（以下简称“千越”或本公司）研发的所有平台软件（以下简称“平台”）。</p>
                <p>1、您使用系统的过程中，如果对本系统存在任何质疑，或不同意声明中的任何条款，当立刻停止使用本平台。如果您继续使用本平台，则表示您同意本声明中的所有条款。</p>
                <p>2、当您在使用本平台的过程中，由以下几种情况给您造成的损失，本公司不予承担任何责任：</p>
                <p class="s-p">(1)您通过非法渠道或方式，获取本平台的使用、服务等权利；</p>
                <p class="s-p">(2)因爲您的个人原因，造成账号和密码泄露，从而造成的任何损失；</p>
                <p class="s-p">(3)因爲您的操作不当或失误操作，造成的数据丢失、账号被盗等损失；</p>
                <p class="s-p">(4)因您个人或者您工作单位的电脑软件、系统、硬件、通讯、其它设备等损坏而造成损失；</p>
                <p class="s-p">(5)因您的网络安全而造成的损失，包括但不限于病毒、木马、黑客攻击或其它恶意破坏；</p>
                <p class="s-p">(6)基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护；</p>
                <p class="s-p">(7)因不可抗力而造成的损失，包括但不限于自然灾害、罢工、动乱、政府行为、政策调整、法律法规调整；</p>
                <p class="s-p">(8)其他本公司无法控制或合理预见的情形。</p>
                <p>3、本公司根据市场环境、公司发展、产品升级等因素，会不定时对本声明进行调整、修改，如果您不同意修改或调整内容，可立刻停止使用本系统，并及时与本公司客服人员联系。如果您继续访问或使用本平台，则视为您已经接受本声明的修改或调整。</p>
                <p>4、本公司提供的平台、软件、服务等内容，将尽最大努力确保连贯性和安全性，但本公司不能保证本平台及本公司所提供的服务毫无瑕疵，且对可能存在的瑕疵不承担责任。</p>
                <p>5、本平台上所有的医学内容、资料、数据等仅供辅助参考, 不能也不应作为诊断及治疗的最终依据，最终的诊疗结果由使用本平台的医护人员作出判断，使用人或接受服务的人对使用本公司软件的后果承担风险，因此而发生的任何纠纷、赔偿、投诉、处罚、处分与本公司无关，本公司不承担任何责任。</p>
                <p>6、本公司对所有平台、软件等产品拥有著作权、商标权等知识产权，受相关法律、法规的保护。任何单位或个人不得在没有本公司授权的情况下对本公司开发的任何产品进行任何非法操作，包括但不限于反编译、二次开发、功能修改等。</p>
                <p>7、为了提供更加完善的服务，本公司有权定期或不定期地对服务平台或相关设备进行升级维护，包括但不限于检修、维护、升级等，届时本平台将提前12小时以平台消息通知的方式告知您，如因升级维护而造成本平台在合理时间内中断或暂停的，本公司不承担责任。</p>
                <p>8、如因网络状况、通讯线路等任何技术原因而导致升级维护失败、暂停等情况，本公司承诺将全力尽快处理问题，整个升级维护过程中，因您的不配合而造成的损失，本公司不承担责任。</p>
                <p>9、本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。</p>
                <br>
                <br>
                <p>特此声明</p>
                <br>
                <p style="text-align: right">声明人：河南千越医疗科技有限公司</p>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "disclaimer",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .disclaimer{
        background: #f4f4f4;
    }
    .xi .banner{
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }
    .banner-text{
        width: 1200px;
        margin: 0 auto;
    }
    .banner-text h3{
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }
    .banner-text p{
        font-size: 18px;
        color: #ffffff;
    }
    .disclamer-box {
        margin: 30px auto;
        width: 1200px;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #e5e5e5;
    }
    .disclamer-tit{
        padding: 10px 0 20px;
        border-bottom: 1px solid #e5e5e5;
    }
    .disclamer-tit h3{
        font-size: 20px;
        color: #333333;
        text-align: center;
        font-weight: bold;
    }
    .disclamer-content{
        padding: 25px 0;
    }
    .disclamer-content p{
        text-indent: 28px;
        line-height: 2;
    }
    .disclamer-content p.s-p{
        text-indent: 42px;
    }
    .disclamer-content h4{
        text-indent: 28px;
        line-height: 1.7;
        padding: 15px 0;
    }
</style>
